<template>
  <b-container id="saml-configuration-list" class="mt-4" fluid>
    <b-row>
      <b-col cols="6">
        <h3>List of SAML Configurations</h3>
      </b-col>
      <b-col v-if="$isSudo" cols="6">
        <b-button variant="primary"
                  class="float-right"
                  :to="{ name: 'SamlCoreConfigurationNew' }">
          <feather type="plus"></feather>
          New Configuration
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="12">
        <b-table
          :fields="fields" :busy="loading" :items="configurations"
          outlined sticky-header="800px" striped small
          empty-text="No Configurations to show">

          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(actions)="data">
            <b-button variant="light"
                      :to="{ name: 'SamlCoreConfigurationView', params: { uuid: data.item.uuid } }">
              <b-icon font-scale="1" icon="eye" aria-label="View configuration"></b-icon>
            </b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Vue from 'vue';
import { list } from '@/api/saml/core/configurations';

export default {
  name: 'SamlCoreConfigurationList',
  beforeMount() {
    this.loading = true;
    list()
      .then(configurations => {
        this.configurations = configurations;
      })
      .catch(err => {
        Vue.prototype.$noty.error(`Failed to fecth configurations: ${err}`);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  data() {
    return {
      loading: false,
      fields: [
        { key: 'name', label: 'Name' },
        { key: 'actions', label: 'Actions' },
      ],
      configurations: [],
    };
  },
};
</script>
