import axios from 'axios';

export function list() {
  return axios.get('/v1/saml/core/configurations')
    .then(({ data: { configurations } }) => configurations);
}

export function get(uuid) {
  return axios.get(`/v1/saml/core/configurations/${uuid}`)
    .then(({ data }) => data);
}

export function create(data) {
  return axios.post(
    '/v1/saml/core/configurations',
    data,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  )
    .then(({ data: { uuid } }) => uuid);
}

export default {};
